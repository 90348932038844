/* https://www.biancolavoro.it/stipendio.js */

const getIrpefLordaRegionale = (regione, redditoImponibile) => {
  /*Tabella regione */
  var IRPEFlordaRegionale=0;
  if(regione==="abruzzo"){
    IRPEFlordaRegionale=redditoImponibile*1.4/100;
  }
  else if(regione==="basilicata"){
    IRPEFlordaRegionale=redditoImponibile*0.9/100;
  }
  else if(regione==="calabria"){
    IRPEFlordaRegionale=redditoImponibile*1.4/100;
  }
  else if(regione==="campania"){
    IRPEFlordaRegionale=redditoImponibile*1.4/100;
  }
  else if(regione==="emiliaRomagna"){
    if(redditoImponibile<15001){
      IRPEFlordaRegionale=redditoImponibile*1.1/100;
    }
    else if(redditoImponibile<20001){
      IRPEFlordaRegionale=redditoImponibile*1.2/100-15000*0.1/100;
    }
    else if(redditoImponibile<25001){
      IRPEFlordaRegionale=redditoImponibile*1.3/100-15000*0.2/100-5000*0.1/100;
    }
    else if(redditoImponibile>=25001){
      IRPEFlordaRegionale=redditoImponibile*1.4/100-15000*0.3/100-5000*0.2/100-5000*0.1/100;
    }
  }
  else if(regione==="friuliVenezia"){
    IRPEFlordaRegionale=redditoImponibile*0.9/100;
  }
  else if(regione==="lazio"){
    IRPEFlordaRegionale=redditoImponibile*1.4/100;
  }
  else if(regione==="liguria"){
    if(redditoImponibile<25001){
      IRPEFlordaRegionale=redditoImponibile*0.9/100;
    }
    else if(redditoImponibile>=25001){
      IRPEFlordaRegionale=redditoImponibile*1.4/100-25000*0.5/100;
    }
  }
  else if(regione==="lombardia"){
    if(redditoImponibile<15493.72){
      IRPEFlordaRegionale=redditoImponibile*0.9/100;
    }
    else if(redditoImponibile<30987.42){
      IRPEFlordaRegionale=15493.71*0.9/100+(redditoImponibile-15493.72)*1.3/100;
    }
    else if(redditoImponibile>=30987.42){
      IRPEFlordaRegionale=15493.71*0.9/100+(30987.41-15493.71)*1.3/100+(redditoImponibile-30987.41)*1.4/100;
    }
  }
  else if(regione==="marche"){
    if(redditoImponibile<=15500){
      IRPEFlordaRegionale=redditoImponibile*0.9/100;
    }
    else if(redditoImponibile<=31000){
      IRPEFlordaRegionale=15500*0.9/100+(redditoImponibile-15500)*1.2/100;
    }
    else if(redditoImponibile>31000){
      IRPEFlordaRegionale=15500*0.9/100+(31000-15500)*1.2/100+(redditoImponibile-31000)*1.4/100;
    }
  }
  else if(regione==="molise"){
    IRPEFlordaRegionale=redditoImponibile*1.4/100;
  }
  else if(regione==="piemonte"){
    if(redditoImponibile<=15000){
      IRPEFlordaRegionale=redditoImponibile*0.9/100;
    }
    else if(redditoImponibile<=22000){
      IRPEFlordaRegionale=15000*0.9/100+(redditoImponibile-15000)*1.2/100;
    }
    else if(redditoImponibile>22000){
      IRPEFlordaRegionale=15000*0.9/100+(22000-15000)*1.2/100+(redditoImponibile-22000)*1.4/100;
    }
  }
  else if(regione==="bolzano"){
    IRPEFlordaRegionale=redditoImponibile*0.9/100;
  }
  else if(regione==="puglia"){
    if(redditoImponibile<=28000){
      IRPEFlordaRegionale=redditoImponibile*0.9/100;
    }
    else if(redditoImponibile>28000){
      IRPEFlordaRegionale=28000*0.9/100+(redditoImponibile-28000)*1.4/100;
    }
  }
  else if(regione==="sardegna"){
    IRPEFlordaRegionale=redditoImponibile*0.9/100;
  }
  else if(regione==="sicilia"){
    IRPEFlordaRegionale=redditoImponibile*1.4/100;
  }
  else if(regione==="toscana"){
    IRPEFlordaRegionale=redditoImponibile*0.9/100;
  }
  else if(regione==="trentino"){
    IRPEFlordaRegionale=redditoImponibile*0.9/100;
  }
  else if(regione==="umbria"){
    if(redditoImponibile<=15000){
      IRPEFlordaRegionale=redditoImponibile*0.9/100;
    }
    else if(redditoImponibile>15000){
      IRPEFlordaRegionale=15000*0.9/100+(redditoImponibile-15000)*1.1/100;
    }
  }
  else if(regione==="aosta"){
    IRPEFlordaRegionale=redditoImponibile*0.9/100;
  }
  else if(regione==="veneto"){
    if(redditoImponibile<=29500){
      IRPEFlordaRegionale=redditoImponibile*0.9/100;
    }
    else if(redditoImponibile>29500){
      IRPEFlordaRegionale=29500*0.9/100+(redditoImponibile-29500)*1.4/100;
    }
  }

  return IRPEFlordaRegionale;

}

export default getIrpefLordaRegionale;